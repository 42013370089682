import React from 'react'

import IconButton from '../../Luxkit/Button/IconButton'
import LineTimesIcon from 'components/Luxkit/Icons/line/LineTimesIcon'

interface Props extends Omit<React.ComponentProps<typeof IconButton>, 'kind' | 'children'> {
}

function CloseButton(props: Props) {
  return <IconButton
    kind="tertiary"
    aria-label="Close"
    {...props}
    data-testid="close-banner-button"
  >
    <LineTimesIcon />
  </IconButton>
}

CloseButton.defaultProps = {
  size: 'small',
  variant: 'dark',
}

export default CloseButton

import { createSelector } from 'reselect'
import { OfferPageState } from 'contexts/OfferPage/offerPageStateReducer'
import { ShareModalConfig } from 'lib/customer/getShareModalConfig'
import { sortBy } from 'lib/array/arrayUtils'
import findCheapestTourV2PurchasableOption from 'lib/tours/findCheapestTourV2PurchasableOption'
import { isTourV2Item } from 'lib/checkout/checkoutUtils'
import { TOUR_V2_PRIVATE_REQUEST_EXCLUDED_REGIONS } from 'constants/config/region'
import { isLuxPlusToursEnabled } from 'checkout/selectors/tourV2Selectors'
import { startOfMonth } from 'lib/datetime/dateUtils'

export const getTourV2CartItems = createSelector(
  (state: App.State) => state.checkout.cart.items,
  (checkoutCartItems): Array<App.Checkout.TourV2Item> => checkoutCartItems?.filter((cartItem): cartItem is App.Checkout.TourV2Item => isTourV2Item(cartItem)) ?? [],
)

export const getTourV2OfferPageBaseUrl = createSelector(
  (offerPageState: OfferPageState) => offerPageState.tourV2Offer?.id,
  (tourV2OfferId) => `/tour/${tourV2OfferId}`,
)

export const getTourV2ShareModalConfig = createSelector(
  (appState: App.State) => appState.config.customerPortalURL,
  (appState: App.State) => appState.auth.account.memberId,
  (appState: App.State) => appState.router.location.pathname,
  (appState: App.State) => appState.geo.currentRegionCode,
  (appState: App.State, currentTourV2Offer: Tours.TourV2Offer) => currentTourV2Offer,
  (
    customerPortalURL,
    memberId,
    pathname,
    region,
    tourV2Offer,
  ): ShareModalConfig => tourV2Offer ?
      {
        customerPortalURL,
        memberId,
        offerId: tourV2Offer.id,
        offerLocation: tourV2Offer.name,
        pathname,
        region,
        type: 'offer',
        utmCampaign: 'webshare',
      } :
    undefined,
)

export function isTourPurchasable(tourOffer: Tours.TourV2Offer) {
  return !!tourOffer.purchasableOptions.length && Object.values(tourOffer.departures).some((d) => d.status === 'available')
}

export const getIsTourOfferPurchasable = createSelector(
  (offerPageState: OfferPageState) => offerPageState.tourV2Offer,
  isTourPurchasable,
)

export const getCheapestPurchasableOption = createSelector(
  (offerPageState: OfferPageState) => offerPageState.tourV2Offer,
  (offerPageState: OfferPageState) => offerPageState.tourV2Variation,
  (offerPageState: OfferPageState, appState: App.State) => isLuxPlusToursEnabled(appState),
  (
    offer,
    variation,
    isLuxPlusToursAvailable,
  ): Tours.TourV2OfferPurchasableOption | undefined => {
    return findCheapestTourV2PurchasableOption(
      offer,
      variation.id,
      isLuxPlusToursAvailable,
    )
  },
)

export const getVariationsDepartureBoundaries = createSelector(
  (offerPageState: OfferPageState) => offerPageState.tourV2Offer.variations,
  (offerPageState: OfferPageState) => offerPageState.tourV2Offer.departures,
  (
    variations,
    departures,
  ): Tours.V2OfferVariationsDepartureBoundaries => {
    const variationsAvailableDepartureBoundaries: Tours.V2OfferVariationsDepartureBoundaries = {}
    const variationsUnavailableDepartureBoundaries: Tours.V2OfferVariationsDepartureBoundaries = {}

    for (const departureId in departures) {
      const departure = departures[departureId]
      const variationsDepartureBoundaries = departure.status === 'available' ? variationsAvailableDepartureBoundaries : variationsUnavailableDepartureBoundaries
      if (!variationsDepartureBoundaries[departure.fkVariationId]) {
        variationsDepartureBoundaries[departure.fkVariationId] = {
          earliestDeparture: null,
          latestDeparture: null,
        }
      }
      const variationDepartureBoundaries = variationsDepartureBoundaries[departure.fkVariationId]

      if (
        variationDepartureBoundaries.earliestDeparture === null ||
          new Date(departure.startDate) < new Date(variationDepartureBoundaries.earliestDeparture.startDate)
      ) {
        variationDepartureBoundaries.earliestDeparture = departure
      }
      if (
        variationDepartureBoundaries.latestDeparture === null ||
          new Date(departure.startDate) > new Date(variationDepartureBoundaries.latestDeparture.startDate)
      ) {
        variationDepartureBoundaries.latestDeparture = departure
      }
    }

    for (const variationId in variations) {
      variationsAvailableDepartureBoundaries[variationId] ??= variationsUnavailableDepartureBoundaries[variationId]
    }

    return variationsAvailableDepartureBoundaries
  },
)

export const getSelectedVariationDepartureBoundaries = createSelector(
  (offerPageState: OfferPageState) => offerPageState.tourV2Variation,
  getVariationsDepartureBoundaries,
  (selectedVariation, variationsDepartureBoundaries) => variationsDepartureBoundaries[selectedVariation.id],
)

export const getSortedItinerary = createSelector(
  (offerPageState: OfferPageState) => offerPageState.tourV2Variation!,
  (variation) => sortBy(
    variation.itinerary,
    event => event.startDay,
    'asc',
  ),
)

export const getIsPrivateRequestEnabled = createSelector(
  (offerPageState: OfferPageState) => offerPageState.tourV2Variation!,
  (offerPageState: OfferPageState, appState: App.State) => appState.geo.currentRegionCode,
  (variation, currentRegionCode): boolean => variation.canRequestPrivateDepartures && !TOUR_V2_PRIVATE_REQUEST_EXCLUDED_REGIONS.includes(currentRegionCode),
)

export const getFilteredDepartures = createSelector(
  (state: OfferPageState) => state.tourV2Variation?.departures,
  (state: OfferPageState) => state.departureYear,
  (state: OfferPageState) => state.departureMonth,
  (allDepartures = [], departureYear, departureMonth) => {
    let departures = allDepartures
    if (departureYear) {
      departures = departures.filter(departure => new Date(departure.startDate).getFullYear() === departureYear)
    }

    if (departureMonth) {
      const filterDate = startOfMonth(new Date(departureMonth.year, departureMonth.month))
      departures = departures.filter(departure => new Date(departure.startDate) >= filterDate)
    }
    return departures
  },
)

import moment from 'moment'

export default function getCheckInOutText(checkIn?: moment.Moment, checkOut?: moment.Moment) {
  if (checkIn && checkOut) {
    try {
      const textParts: Array<string> = []
      if (checkIn.month() !== checkOut.month()) {
        textParts.push(checkIn.format('D MMM'))
      } else {
        textParts.push(checkIn.format('D'))
      }
      textParts.push(...[
        ' - ',
        checkOut.format('D MMM'),
      ])
      return textParts.join('')
    } catch {

    }
  }
  return ''
}

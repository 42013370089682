import { TrackingProps } from 'contexts/trackingContext'
import React, { useContext, useEffect, useMemo } from 'react'
import { useAppSelector } from 'hooks/reduxHooks'
import { getHotelOfferDisplayedValue } from 'lib/offer/getOfferDisplayPricing'
import { getYMALViewForOffer } from 'components/Recommendations/YouMayAlsoLike/Shared/YouMayAlsoLikeOffersCarousel'
import GeoContext from 'contexts/geoContext'
import useOffer from 'hooks/Offers/useOffer'
import { getDefaultDepositAmountPercentage } from 'checkout/selectors/featureConfig/deposit'
import HighIntentRecommendationTileLoader from './HighIntentRecommendationTileLoader'
import { ENABLED_OFFER_TYPES_RECENTLY_VIEWED, isOfferValidWithRegion } from 'lib/offer/highIntentOffers'
import useBedbankRates from 'hooks/useBedbankRates'
import { isBedbankOffer } from 'lib/offer/offerTypes'
import config from 'constants/config'
import moment from 'moment'
import HighIntentRecommendationTile, { HighIntentTileProps } from 'components/OfferList/HighIntent/HighIntentRecommendationTile'
import { HighIntentOfferDisplayStatus } from 'home/components/OfferCarousels/HighIntentOfferCard'
import { checkCanViewLuxPlusBenefits } from 'luxPlus/selectors/featureToggle'
import useOptimizelyExperiment from 'hooks/Optimizely/useOptimizelyExperiment'
import { OptimizelyExperiments } from 'constants/optimizely'
import { OFFER_TYPE_HOTEL } from 'constants/offer'

export function shouldDisplay(offerType: App.OfferType) {
  return ENABLED_OFFER_TYPES_RECENTLY_VIEWED.has(offerType)
}

interface Props {
  offerId: string;
  highIntentCategory: App.HighIntentCategory;
  position: number;
  tracking?: TrackingProps;
  lereVersion?: string;
  onClick?: HighIntentTileProps['onClick'];
  onClose?: HighIntentTileProps['onClose'];
  className?: string;
  onDisplayStatusChange?: (status: HighIntentOfferDisplayStatus) => void;
}

const tileTestId = 'recently-viewed-tile'

function RecentlyViewedTile(props: Props) {
  const { offerId, highIntentCategory, position, tracking, lereVersion, onClick, onClose, onDisplayStatusChange, className } = props
  const { currentCurrency, currentRegionCode } = useContext(GeoContext)
  const [offer, fetching, offerError] = useOffer<App.Offer>(offerId)

  const defaultDepositAmountPercentage = useAppSelector(getDefaultDepositAmountPercentage)

  const isFlashOffer = offer?.type === OFFER_TYPE_HOTEL
  const countdownLabelVariant = useOptimizelyExperiment(OptimizelyExperiments.croOfferEndingSoonUrgencyTag, isFlashOffer)

  // These are for bedbanks, we try to get 1 night price in 2 weeks
  const { checkIn, checkOut } = useMemo(() => ({
    checkIn: moment().add(2, 'weeks').format('YYYY-MM-DD'),
    checkOut: moment().add(2, 'weeks').add(1, 'day').format('YYYY-MM-DD'),
  }
  ), [])
  const [{ hotelOnlyRates }, fetchingRates] = useBedbankRates(offerId, [config.search.defaultOccupants], checkIn, checkOut, isBedbankOffer(offer))
  const bedbankRate = hotelOnlyRates[0]

  const view = useMemo(() => {
    if (offer) {
      return getYMALViewForOffer(offer, {
        testId: tileTestId,
        position,
        currency: currentCurrency,
        tracking,
        defaultDepositAmountPercentage,
        currentRegionCode,
        countdownLabelVariant,
      })
    }
  }, [currentCurrency, currentRegionCode, defaultDepositAmountPercentage, offer, position, tracking, countdownLabelVariant])

  const {
    category,
    flights,
    image,
    title,
    pricing,
    shouldDisplayValue,
    testId,
    url,
    value,
    urgencyLabels,
  } = (view ?? {}) as any
  // ------- Render props -------------
  let { price, duration, memberPrice } = pricing ?? {}

  let displayedPrice
  const canViewLuxPlusBenefits = useAppSelector(checkCanViewLuxPlusBenefits)

  displayedPrice = (canViewLuxPlusBenefits && memberPrice > 0 ? memberPrice : price) ?? 0

  if (isBedbankOffer(offer) && !value) {
    displayedPrice = bedbankRate?.totals.inclusive + (bedbankRate?.totals.propertyFees ?? 0)
    duration = '1 night'
  }

  const displayValue = getHotelOfferDisplayedValue(false, displayedPrice, value ?? 1, !!shouldDisplayValue)
  const { shouldShowDiscountPercent, highestDiscountPercent } = displayValue
  const discountPercentage = (!offer?.isDiscountPillHidden && shouldShowDiscountPercent) ? highestDiscountPercent : undefined
  const durationText = duration ? `${duration} from` : 'From'

  const isValid = useMemo(() => offer ? isOfferValidWithRegion(offer, currentRegionCode) : false, [offer, currentRegionCode])

  const updatedTracking = useMemo(() => (tracking ? {
    ...tracking,
    category,
    lere: {
      version: lereVersion,
    },
  } : undefined), [tracking, category, lereVersion])

  const isLoading = fetching || (isBedbankOffer(offer) && fetchingRates)

  const shouldNotDisplay = offer?.type && !shouldDisplay(offer.type) || !!flights?.bundledWithFlightsOnly || !isValid || offerError

  useEffect(() => {
    if (isLoading) {
      onDisplayStatusChange?.(HighIntentOfferDisplayStatus.LOADING)
    } else if (shouldNotDisplay) {
      onDisplayStatusChange?.(HighIntentOfferDisplayStatus.HIDE)
    } else {
      onDisplayStatusChange?.(HighIntentOfferDisplayStatus.SHOW)
    }
  }, [isLoading, onDisplayStatusChange, shouldNotDisplay])

  if (isLoading) {
    return <HighIntentRecommendationTileLoader />
  }

  if (shouldNotDisplay) {
    return null
  }

  const tileProperties: HighIntentTileProps = {
    offerId,
    highIntentCategory,
    url,
    title,
    image,
    urgencyLabels,
    durationText,
    duration: pricing?.hotelNights,
    displayedPrice,
    isMemberPrice: memberPrice > 0,
    discountPercentage,
    position,
    tracking: updatedTracking,
    testId,
    onClick,
    onClose,
    className,
  }

  return (
    <HighIntentRecommendationTile {...tileProperties} />
  )
}

export default React.memo(RecentlyViewedTile)

import useCheckoutOfferData from 'checkout/hooks/useCheckoutOfferData'
import { useAppSelector } from 'hooks/reduxHooks'
import { shallowEqual } from 'react-redux'
import { useMemo } from 'react'
import { checkoutAccommodationOfferView } from 'checkout/selectors/view/accommodation'
import { isAbandonedCartSupportedAndAvailable } from 'components/Common/StickyPromptCard/useAbandonedCartSelectionLogic'
import { isFlightForceBundled } from 'lib/offer/isFlightForceBundled'
import getCheckoutTotalsView from 'checkout/selectors/payment/getCheckoutTotalsView'
import useCheckoutTourV2Data from 'checkout/hooks/useCheckoutTourV2Data'

export function useAbandonedCartData(cart: App.AbandonedCartRecommendation) {
  // create a new state by replacing the checkout cart
  const stateOverride = useAppSelector((state) => ({
    ...state,
    checkout: {
      ...state.checkout,
      cart,
    },
  }), shallowEqual)

  // fetch all the offer/price data using the replaced cart
  useCheckoutOfferData(stateOverride)
  useCheckoutTourV2Data(stateOverride)

  const accommodationView = useMemo(() => checkoutAccommodationOfferView(stateOverride), [stateOverride])
  const totalsView = useMemo(() => getCheckoutTotalsView(stateOverride), [stateOverride])
  const abandonedCartOfferView = accommodationView?.data?.find(isAbandonedCartSupportedAndAvailable)

  const offer = abandonedCartOfferView?.offer
  const isFlightBundled = isFlightForceBundled(offer) || !!abandonedCartOfferView?.isBundled

  const isCartDataReady = isFlightBundled || (!!accommodationView?.hasRequiredData && !!totalsView?.hasRequiredData)

  return {
    isCartDataReady,
    totalsView: totalsView?.data,
    abandonedCartOfferView: isFlightBundled ? undefined : abandonedCartOfferView,
  }
}

import { TrackingProps } from 'contexts/trackingContext'
import React, { useContext, useEffect, useMemo } from 'react'

import HighIntentRecommendationTile, { HighIntentTileProps } from 'components/OfferList/HighIntent/HighIntentRecommendationTile'
import getCheckInOutText from 'components/Recommendations/utils/getCheckInOutText'
import { getOccupancyText } from 'components/Recommendations/utils/getOccupancyText'
import { MINIMUM_DISCOUNT_TO_SHOW_PERCENTAGE_BADGE } from 'constants/content'
import GeoContext from 'contexts/geoContext'
import useOffer from 'hooks/Offers/useOffer'
import { getHotLeadDurationText, getTimeLeftUrgencyLabels, isOfferValidWithRegion as isOfferScheduleValid } from 'lib/offer/highIntentOffers'
import { getOfferName } from 'lib/offer/offerUtils'
import moment from 'moment'
import HighIntentRecommendationTileLoader from './HighIntentRecommendationTileLoader'
import { useHotLeadData } from 'hooks/Recommendation/useHotLeadData'
import { nonNullable } from 'lib/array/arrayUtils'
import { countOccupants } from 'lib/offer/occupancyUtils'
import { HighIntentOfferDisplayStatus } from 'home/components/OfferCarousels/HighIntentOfferCard'
import useOptimizelyExperiment from 'hooks/Optimizely/useOptimizelyExperiment'
import { OptimizelyExperiments } from 'constants/optimizely'
import { OFFER_TYPE_HOTEL } from 'constants/offer'

interface Props {
  highIntentOffer: App.HotLeadOffer;
  position: number;
  tracking?: TrackingProps;
  onClick?: HighIntentTileProps['onClick'];
  onClose?: HighIntentTileProps['onClose'];
  className?: string;
  onDisplayStatusChange?: (status: HighIntentOfferDisplayStatus) => void;
}

const tileTestId = 'hot-lead-tile'

function HotLeadTile(props: Props) {
  const { highIntentOffer, position, tracking, onClick, onClose, onDisplayStatusChange, className } = props
  const { hotLeadDataReady, hotLeadData } = useHotLeadData(highIntentOffer)
  const [offer, offerLoading, offerError] = useOffer<App.Offer>(highIntentOffer.offerId)
  const { currentRegionCode } = useContext(GeoContext)
  const isScheduleValid = useMemo(() => offer ? isOfferScheduleValid(offer, currentRegionCode) : false, [offer, currentRegionCode])
  const category = offer?.analyticsType

  const isFlashOffer = offer?.type === OFFER_TYPE_HOTEL
  const countdownLabelVariant = useOptimizelyExperiment(OptimizelyExperiments.croOfferEndingSoonUrgencyTag, isFlashOffer)

  const updatedTracking = useMemo(() => (tracking ? {
    ...tracking,
    category,
    lere: {
      version: highIntentOffer.lereVersion,
    },
  } : undefined), [tracking, category, highIntentOffer])

  const shouldNotDisplay = !isScheduleValid || !hotLeadData

  useEffect(() => {
    if (!hotLeadDataReady || offerLoading) {
      onDisplayStatusChange?.(HighIntentOfferDisplayStatus.LOADING)
    } else if (hotLeadDataReady && (offerError || shouldNotDisplay)) {
      onDisplayStatusChange?.(HighIntentOfferDisplayStatus.HIDE)
    } else {
      onDisplayStatusChange?.(HighIntentOfferDisplayStatus.SHOW)
    }
  }, [offerError, shouldNotDisplay, hotLeadDataReady, onDisplayStatusChange, offerLoading])

  if (!hotLeadDataReady || offerLoading) {
    return <HighIntentRecommendationTileLoader />
  }

  if (shouldNotDisplay) {
    return null
  }

  const {
    checkIn,
    checkOut,
    duration,
    occupancy,
    displayedPrice,
    isMemberPrice,
    discountPercent,
    offerUrl,
  } = hotLeadData

  const image = offer.image
  const title = getOfferName(offer)!

  const checkInMoment = checkIn ? moment(checkIn) : undefined
  const checkOutMoment = checkOut ? moment(checkOut) : undefined
  const checkInOutText = getCheckInOutText(checkInMoment, checkOutMoment)

  // do not display sale unit (X rooms) if we have BOTH dates and children/infant
  const filteredOccupants = nonNullable(occupancy)
  const { children, infants } = countOccupants(filteredOccupants)
  const saleUnit = (!!checkInOutText && !!(children + infants)) ? undefined : offer.saleUnit
  const occupancyText = getOccupancyText(filteredOccupants, saleUnit)

  const urgencyLabels = getTimeLeftUrgencyLabels(offer, currentRegionCode, countdownLabelVariant)

  const durationText = getHotLeadDurationText(duration, offer.type)
  const discountPercentage = (!offer?.isDiscountPillHidden && discountPercent && (discountPercent >= MINIMUM_DISCOUNT_TO_SHOW_PERCENTAGE_BADGE)) ? discountPercent : undefined

  if (offerError) {
    return null
  }

  const tileProperties: HighIntentTileProps = {
    offerId: highIntentOffer.offerId,
    highIntentCategory: highIntentOffer.category,
    url: offerUrl,
    title,
    image,
    checkInOutText,
    occupancyText,
    urgencyLabels,
    durationText,
    duration,
    displayedPrice,
    isMemberPrice,
    discountPercentage,
    position,
    tracking: updatedTracking,
    testId: tileTestId,
    onClick,
    onClose,
    className,
  }

  return (
    <HighIntentRecommendationTile {...tileProperties} />
  )
}

export default React.memo(HotLeadTile)

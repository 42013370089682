import { formatOccupantsShort } from 'lib/offer/occupancyUtils'
import { pluralizeToString } from 'lib/string/pluralize'

export function getOccupancyText(rooms: Array<App.Occupants>, saleUnit?: string) {
  let text = formatOccupantsShort(rooms)
  if (!!saleUnit && !!rooms.length) {
    text += ` · ${pluralizeToString(saleUnit, rooms.length)}`
  }
  return text
}

export function getBnblOccupancyText(numberOfRooms: number, saleUnit?: string) {
  if (!!saleUnit && !!numberOfRooms) {
    return pluralizeToString(saleUnit, numberOfRooms)
  }
}

import {
  OFFER_TYPE_ALWAYS_ON,
  OFFER_TYPE_BED_BANK,
  OFFER_TYPE_HOTEL,
  OFFER_TYPE_LAST_MINUTE,
  OFFER_TYPE_TOUR,
  OFFER_TYPE_TOUR_V2,
} from 'constants/offer'
import { sum } from 'lib/array/arrayUtils'
import { isTourPurchasable } from 'selectors/TourV2/tourV2OfferPageSelectors'
import { isOfferAvailable } from 'selectors/recommendations'

export type AcSupportedAccommodationOfferView = (
  App.Checkout.LEAccommodationOfferView |
  App.Checkout.BedbankAccommodationOfferView |
  App.Checkout.TourV2AccommodationOfferView
)

const isHotel = (item: App.Checkout.AccommodationOfferView): item is App.Checkout.LEAccommodationOfferView | App.Checkout.BedbankAccommodationOfferView => {
  return (item.offerType === OFFER_TYPE_ALWAYS_ON ||
    item.offerType === OFFER_TYPE_LAST_MINUTE ||
    item.offerType === OFFER_TYPE_BED_BANK ||
    item.offerType === OFFER_TYPE_HOTEL)
}

function isTourV2(item: App.Checkout.AccommodationOfferView): item is App.Checkout.TourV2AccommodationOfferView {
  // v1 tour is no longer in prod
  return item.offerType === OFFER_TYPE_TOUR_V2
}

export function isCruiseV1(item: App.Checkout.AccommodationOfferView): item is App.Checkout.LEAccommodationOfferView {
  // cruise v1 is implemented as tour v1 (whereas the real tour v1 is no longer in prod)
  return item.offerType === OFFER_TYPE_TOUR
}

function isHotelAvailable(hotelView: App.Checkout.LEAccommodationOfferView | App.Checkout.BedbankAccommodationOfferView) {
  return (hotelView.offerType === OFFER_TYPE_BED_BANK || (!!hotelView.offer && isOfferAvailable(hotelView.offer))) && getTotalPrice(hotelView) > 0
}

function isTourV2Available(tourView: App.Checkout.TourV2AccommodationOfferView) {
  return !!tourView.offer && isTourPurchasable(tourView.offer) && getTotalPrice(tourView) > 0
}

function isCruiseV1Available(cruiseView: App.Checkout.LEAccommodationOfferView) {
  return !!cruiseView.offer && isOfferAvailable(cruiseView.offer) && getTotalPrice(cruiseView) > 0
}

export function isAbandonedCartSupportedAndAvailable(item: App.Checkout.AccommodationOfferView): item is AcSupportedAccommodationOfferView {
  return (
    (isHotel(item) && isHotelAvailable(item)) ||
    (isTourV2(item) && isTourV2Available(item)) ||
    (isCruiseV1(item) && isCruiseV1Available(item))
  )
}

export function getTotalPrice(offerView: AcSupportedAccommodationOfferView) {
  const price = sum<App.Checkout.ItemTotals>(offerView.itemViews, (item) => (item.price ?? 0) + (item.surcharge ?? 0))
  return price
}

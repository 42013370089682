import CardCarousel from 'components/Luxkit/Carousel/CardCarousel'
import { useAppDispatch } from 'hooks/reduxHooks'
import uuidV4 from 'lib/string/uuidV4Utils'
import React, { useCallback, useMemo } from 'react'
import { removeHighIntentOffers } from 'actions/RecommendationActions'
import { TrackingProps } from 'contexts/trackingContext'
import HighIntentOfferCard from 'home/components/OfferCarousels/HighIntentOfferCard'
interface Props {
  highIntentOffers: Array<App.HighIntentOffer>;
  analyticsLabel?: string;
  onClick?: () => void;
}

function HighIntentOffersCarousel(props: Props) {
  const {
    highIntentOffers,
    analyticsLabel,
    onClick,
  } = props
  const dispatch = useAppDispatch()

  const tracking: TrackingProps = useMemo(() => ({
    category: 'High Intent Offers',
    listName: 'High Intent Recommendations Carousel',
    listId: uuidV4(),
    label: analyticsLabel ?? '',
  }), [analyticsLabel])

  const onClose = useCallback((offerId: string, category: App.HighIntentCategory) => {
    dispatch(removeHighIntentOffers([offerId], [category]))
  }, [dispatch])

  if (highIntentOffers.length === 0) {
    return null
  }

  return <CardCarousel pageSize={3}>
    {highIntentOffers.map((highIntentOffer, index) => (
      <HighIntentOfferCard
        position={index + 1}
        highIntentOffer={highIntentOffer}
        tracking={tracking}
        key={highIntentOffer.offerId}
        onClose={onClose}
        onClick={onClick || (() => {})}
      />))}
  </CardCarousel>
}

export default React.memo(HighIntentOffersCarousel)

import LoadingBox from 'components/Common/Loading/LoadingBox'
import TextLoadingBox from 'components/Luxkit/Typography/TextLoadingBox'
import Pane from 'components/Common/Pane'
import Group from 'components/utils/Group'
import { rem } from 'polished'
import React from 'react'
import styled from 'styled-components'
import { CAROUSEL_CARD_WIDTH } from 'components/OfferList/OfferCards/OfferCardSmall/CarouselCardSmall'

const Card = styled(Pane)`
  width: ${rem(CAROUSEL_CARD_WIDTH)};
  display: grid;
  grid-template-columns: ${rem(100)} 1fr;
  min-height: ${rem(108)};
  height: 100%;
  overflow: hidden;
  box-shadow: none;

  &.tile-border {
    border: 1px solid ${props => props.theme.palette.neutral.default.five};
    border-radius: ${props => props.theme.borderRadius.XS};
  }
`

const Section = styled(Group)`
  padding: ${rem(12)} ${rem(16)};
`

function HighIntentRecommendationTileLoader() {
  return <Card>
    <LoadingBox />
    <Section direction="vertical">
      <TextLoadingBox typography="caption-small" width={90} />
      <TextLoadingBox typography="body-small" width={120} />
      <TextLoadingBox typography="caption-small" width={130} />
    </Section>
  </Card>
}

export default React.memo(HighIntentRecommendationTileLoader)
